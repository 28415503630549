@font-face {
    font-family: 'iTroodSan';
    src: url('/src/assets/fonts/iTroodSan-Bold.woff2') format('woff2'),
         url('/src/assets/fonts/iTroodSan-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'iTroodSan';
    src: url('/src/assets/fonts/iTroodSan-Medium.woff2') format('woff2'),
         url('/src/assets/fonts/iTroodSan-Medium.otf') format('opentype');
    font-weight: 500; /* Adjust weight value if necessary */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'iTroodSan';
    src: url('/src/assets/fonts/iTroodSan-Light.woff2') format('woff2'),
         url('/src/assets/fonts/iTroodSan-Light.otf') format('opentype');
    font-weight: 300; /* Adjust weight value if necessary */
    font-style: normal;
  }
  
  