@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom scrollbar width and color for larger screens */
@media (min-width: 768px) {
  .scrollbar-wider::-webkit-scrollbar {
    width: 12px;
    /* Adjust scrollbar width for larger screens */
  }

  .scrollbar-wider::-webkit-scrollbar-thumb {
    background-color: #ab79f3;
    /* Adjust thumb color for larger screens */
  }
}

/* Custom scrollbar width and color for all screens */
.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
  /* Adjust scrollbar width for all screens */
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  /* Adjust thumb color for all screens */
}

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* font-family: Plus Jakarta Sans;
font-size: 20px;
font-weight: 400;
line-height: 19.6px;
text-align: left; */

:root {
  --black-gradient: linear-gradient(144.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%);
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

.text-gradient {
  background: radial-gradient(64.18% 64.18% at 71.16% 35.69%,
      #def9fa 0.89%,
      #bef3f5 17.23%,
      #9dedf0 42.04%,
      #7de7eb 55.12%,
      #5ce1e6 71.54%,
      #33bbcf 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(157.81deg,
      #def9fa -43.27%,
      #bef3f5 -21.24%,
      #9dedf0 12.19%,
      #7de7eb 29.82%,
      #5ce1e6 51.94%,
      #33bbcf 90.29%);
}

.bg-black-gradient {
  background: linear-gradient(144.39deg,
      #ffffff -278.56%,
      #999eb9 -78.47%,
      #e5e4eb 91.61%);
}

.bg-black-gradient-2 {
  background: linear-gradient(-168.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%);
}

.bg-gray-gradient {
  background: linear-gradient(153.47deg,
      rgba(255, 255, 255, 0) -341.94%,
      #14101d 95.11%);
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

.button:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

/* Glow Animation */
@keyframes glow {
  0% {
    box-shadow: 0 0 5px #4d4d4d;
  }
  50% {
    box-shadow: 0 0 20px rgb(97, 5, 112), 0 0 30px #f0f, 0 0 40px #0ff, 0 0 50px #00f;
  }
  100% {
    box-shadow: 0 0 5px #fff;
  }
}

@keyframes glows {
  0% {
    box-shadow: 0 0 0px #4d4d4d;
  }
  50% {
    box-shadow: 0 0 5px rgb(97, 5, 112), 0 0 10px #00f, 0 0 18px #09f, 0 0 25px #00f;
  }
  100% {
    box-shadow: 0 0 2px #fff;
  }
}

/* Grow Button */
.growButton {
  transition: transform 0.3s ease-in-out;
}

.growButton:hover {
  transform: scale(1.1);
  animation: glow 1.5s infinite alternate;
}

.autoGrow {
  transform: scale(1.0);
  animation: glows 2.5s infinite alternate;
}


/* Glitch Animation */
@keyframes glitch {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  20% {
    transform: translate(-2px, 2px);
    opacity: 0.8;
  }
  40% {
    transform: translate(-2px, -2px);
    opacity: 1;
  }
  60% {
    transform: translate(2px, 2px);
    opacity: 0.8;
  }
  80% {
    transform: translate(2px, -2px);
    opacity: 1;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.glitchButton::before,
.glitchButton::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.glitchButton::before {
  left: 2px;
  text-shadow: -2px 0 red;
  animation: glitch 2.5s infinite linear alternate-reverse;
}

.glitchButton::after {
  left: -2px;
  text-shadow: -2px 0 blue;
  animation: glitch 3s infinite linear alternate-reverse;
}

.glitchButton:hover {
  animation: glitch 1s infinite;
}

.slider-image {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slider-image.active {
  opacity: 1;
}
